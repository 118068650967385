<template>
  <div>
    <div class="mb-1">
      <b-modal id="password-update-modal" title="Password Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="old_password" class="col-form-label col-form-label-sm">Old Password <span class="custom-required">*</span></label>
              <input type="password" v-model="old_password" v-validate="{ required:true, min:6, max: 15 }"  id="old_password" name="password" class="form-control form-control-sm" placeholder="Enter old password"/>
              <div class="invalid-feedback">Old password is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="password" class="col-form-label col-form-label-sm">New Password <span class="custom-required">*</span></label>
              <input type="password" v-model="password" v-validate="{ required:true, min:6, max: 15 }" ref="password" id="password" name="password" class="form-control form-control-sm" placeholder="Enter password"/>
              <div class="invalid-feedback">New password is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="password_confirmation" class="col-form-label col-form-label-sm">Confirm New Password <span class="custom-required">*</span></label>
              <input type="password" v-model="password_confirmation"  v-validate="{ required:true, confirmed:password }" id="password_confirmation" name="password_confirmation" class="form-control form-control-sm" placeholder="Enter confirm password"/>
              <div class="invalid-feedback">Confirm new password is required and not match</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('password-update-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'index',
  components: { },
  data() {
    return {
      validation_errors: {},
      old_password: '',
      password: '',
      password_confirmation: '',
      loader: false,
      show: false,
    }
  },
  mounted() { },
  methods: {
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('old_password', this.old_password)
          formData.append('password', this.password)
          formData.append('password_confirmation', this.password_confirmation)
          apiClient.post('api/user/password/update', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.loader = false
            if (response.data.error) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$bvModal.hide('password-update-modal')
              this.$emit('getUser')
              this.old_password = ''
              this.password = ''
              this.password_confirmation = ''
              this.$notification.success({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
